import React, { useRef, useEffect } from "react";
import { Map, View } from "ol";
import { OSM, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { GeoJSON } from "ol/format";
import "ol/ol.css";
import "./MapDashboard.css";
import Overlay from "ol/Overlay";
import { Style, Icon } from "ol/style";
import { fromLonLat } from "ol/proj";

function OpenLayersMap({ showBar, selectedOptions, checkCategory }) {
  const ref = useRef(null);
  const mapRef = useRef(null);
  const vectorLayersRef = useRef({});
  const pointDataRef = useRef({});
  const overlayRef = useRef(null);

  useEffect(() => {
    if (ref.current && !mapRef.current) {
      mapRef.current = new Map({
        layers: [
          new TileLayer({
            source: new OSM({
              url:
                "https://server.arcgisonline.com/ArcGIS/rest/services/" +
                "World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            }),
          }),
        ],
        target: ref.current,
        view: new View({
          center: fromLonLat([73.87, 23.89]),
          zoom: 4.7,
        }),
      });

      mapRef.current.on("wheel", handleWheelEvent);
      window.addEventListener("keydown", handleKeyDownEvent);
      window.addEventListener("keyup", handleKeyUpEvent);
    } else if (mapRef.current) {
      mapRef.current.getView().animate({
        center: fromLonLat([73.87, 23.89]),
        zoom: 4.7,
        duration: 2000,
      });
    }
  }, [ref]);

  useEffect(() => {
    if (mapRef.current) {
      const mapContent = mapRef.current.getTargetElement();
      const sidebarWidth = !showBar ? 0 : 0;
      mapContent.style.marginLeft = `${sidebarWidth}px`;
    }
  }, [showBar]);

  useEffect(() => {
    if (mapRef.current) {
      selectedOptions.forEach((selectedOption) => {
        if (!vectorLayersRef.current[selectedOption]) {
          const categoryInfo = checkCategory[selectedOption];

          if (categoryInfo) {
            const { category, path } = categoryInfo;
            const vectorLayer = new VectorLayer({
              source: new VectorSource({
                format: new GeoJSON(),
                url: "https://gcrs.co.in/geoserver/iar_gcrs/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=iar_gcrs%3Aiarandchr&outputFormat=application%2Fjson",
              }),
              style: function (feature) {
                const featureCategory = feature.get("project_ty");
                if (featureCategory === category) {
                  return new Style({
                    image: new Icon({
                      src: path,
                      scale: 1,
                    }),
                  });
                }
                return null;
              },
            });

            vectorLayersRef.current[selectedOption] = vectorLayer;
            mapRef.current.addLayer(vectorLayer);

            const pointData = new Overlay({
              element: document.createElement("div"),
              positioning: "bottom-center",
              offset: [0, -15],
            });
            pointDataRef.current[selectedOption] = pointData;
            mapRef.current.addOverlay(pointData);

            mapRef.current.on("pointermove", (event) => {
              const feature = mapRef.current.forEachFeatureAtPixel(
                event.pixel,
                (feature) => feature
              );

              if (feature && feature.get("project_ty") === category) {
                const properties = feature.getProperties();
                const popupContent = `<div class="popup-content">
                    <strong>Category:</strong> ${properties.category}<br/>
                    <strong>Project Type:</strong> ${properties.project_ty}<br/>
                    <strong>Client Name:</strong> ${null}<br/>
                    <strong>Location:</strong> ${null}<br/>
                    <strong>Project Name:</strong> ${null}
                  </div>`;

                pointDataRef.current[selectedOption].getElement().innerHTML =
                  popupContent;
                pointDataRef.current[selectedOption].setPosition(
                  event.coordinate
                );
              } else {
                pointDataRef.current[selectedOption].setPosition(undefined);
              }
            });
          }
        }
      });
    }
  }, [mapRef, selectedOptions, checkCategory, vectorLayersRef]);

  useEffect(() => {
    Object.keys(vectorLayersRef.current).forEach((key) => {
      const layer = vectorLayersRef.current[key];
      const isVisible = selectedOptions.includes(key);
      layer.setVisible(isVisible);
    });
  }, [selectedOptions]);

  const handleKeyDownEvent = (event) => {
    if (event.ctrlKey) {
      const mapContent = mapRef.current.getTargetElement();
      mapContent.style.opacity = 1;
      mapContent.style.pointerEvents = "auto";
      if (overlayRef.current) {
        overlayRef.current.style.display = "none";
      }
    }
  };

  const handleKeyUpEvent = () => {
    const mapContent = mapRef.current.getTargetElement();
    mapContent.style.opacity = 0.7;
    mapContent.style.pointerEvents = "none";
    if (overlayRef.current) {
      overlayRef.current.style.display = "block";
    }
  };

  const handleWheelEvent = (event) => {
    if (!event.ctrlKey) {
      event.preventDefault();
      if (overlayRef.current) {
        overlayRef.current.style.display = "block";
      }
    } else {
      if (overlayRef.current) {
        overlayRef.current.style.display = "none";
      }
    }
  };

  return (
    <div className="mainContainer">
      {showBar && <div className="sideBar"></div>}
      <div ref={ref} className="map-content" style={{opacity:0.7, pointerEvents: "none" }} />
      <div ref={overlayRef} >
        <h1> Use Ctrl + scroll to zoom the map</h1>
      </div>
    </div>
  );
}

export default OpenLayersMap;
