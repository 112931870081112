import React from "react";
import "./Capabilities.css";
import TabComponent from "./capDescription";
import Capa from "./capa";
import { useState } from "react";

const Capabilities = () => {
  const [activeTab, setActiveTab] = useState("climate-adaptation");

  return (
    <>
      <div className="bg-Capabilities">
        <div className="text-container">
          <a href="#scroll-section" style={{ textDecoration: "none" }}>
            <p className="scroll-text">SCROLL</p>
          </a>

          <div className="banner-text3">
            <h1 className="banner-heading"> Capabilities</h1>
            <p className="banner-para3">
              
              We offer a comprehensive skill set to address the intricate and
              systemic challenges that our society and environment encounter
            </p>
          </div>
        </div>
      </div>
      <div id="scroll-section">
        <div className="components-container">
          <div style={{ marginTop: "80px" }}>
            <TabComponent setActiveTab={setActiveTab} />
          </div>
          <div className="comma-container">
            <div className="comma-large-screen">
              <Capa activeTab={activeTab} />
            </div>
          </div>
        </div>

        <div className="comma-small-medium-screen">
          <Capa activeTab={activeTab} />
        </div>
      </div>
    </>
  );
};

export default Capabilities;
