import React, { useState } from "react";
import "./SidebarDashboard.css";

const SidebarDashboard = ({ handleCheckboxChange, checkCategory }) => {
  const [checkboxes, setCheckboxes] = useState({
    Risk: true,
    Water: true,
    Carbon: true,
    Groundwater: true,
    Carbonstock: true,
  });

  const handleCheckboxClick = (imageName) => {
    const updatedCheckboxes = {
      ...checkboxes,
      [imageName]: !checkboxes[imageName],
    };
    setCheckboxes(updatedCheckboxes);
    handleCheckboxChange(updatedCheckboxes);
  };

  const handleSelectAll = () => {
    const allChecked = Object.values(checkboxes).every((value) => value);
    const updatedCheckboxes = Object.fromEntries(
      Object.keys(checkboxes).map((imageName) => [imageName, !allChecked])
    );

    setCheckboxes(updatedCheckboxes);
    handleCheckboxChange(updatedCheckboxes);
  };

  return (
    <div className="side_banner">
      <div className="list-body">
        <div className="checkbox-container">
          <button className="btn-select-all " onClick={handleSelectAll}>
            {Object.values(checkboxes).every((value) => value)
              ? "Unselect All"
              : "Select All"}
          </button>
        </div>

        {Object.entries(checkboxes).map(([imageName, isChecked]) => (
          <div className="checkbox-container" key={imageName}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => handleCheckboxClick(imageName)}
            />

            <img
              src={checkCategory[imageName].path}
              alt={checkCategory[imageName].label}
              style={{ marginRight: "10px" }}
            />
            <label>{getImageLabel(imageName)}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const getImageLabel = (imageName) => {
  switch (imageName) {
    case "Risk":
      return "Risk Assessment";
    case "Water":
      return " Water Stewardship";
    case "Carbon":
      return " Carbon Offset";
    case "Groundwater":
      return " Groundwater Project";
    case "Carbonstock":
      return " Carbonstock Assessment";
    default:
      return "";
  }
};

export default React.memo(SidebarDashboard);
