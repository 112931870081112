import React, { useState } from "react";
import "./aboutusImage.css";

const Image = () => {
  const [selectedItemType, setSelectedItemType] = useState("theme");

  const handleItemClick = (type) => {
    setSelectedItemType(type);
  };

  const itemDescriptions = {};

  const toggleSelectedItem = () => {
    setSelectedItemType(selectedItemType === "theme" ? "product" : "theme");
  };

  const productImage =
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Group%2021.png";
  const themeImage =
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products_1.png";

  return (
    <div className="container" style={{ marginBottom: "30px" }}>
      <div
        className={`heading-line ${selectedItemType}`}
        onClick={toggleSelectedItem}
      ></div>
      {/* <div className="item-buttons">
        <div
          className={`item-button ${selectedItemType === 'theme' ? 'selected' : ''}`}
          onClick={() => handleItemClick('theme')}
          style={{ fontWeight: "bold", fontSize: "25px", }}
        >
          Themes
        </div>
        <div
          className={`item-button ${selectedItemType === 'product' ? 'selected' : ''}`}
          onClick={() => handleItemClick('product')}
          style={{ fontWeight: "bold", fontSize: "25px" }}
        >
          Products
        </div>
      </div> */}
      <div className="item-buttons">
        <div
          className={`item-button ${
            selectedItemType === "theme" ? "selected" : ""
          }`}
          onClick={() => handleItemClick("theme")}
          style={{
            fontWeight: "bold",
            fontSize: "25px",
          }}
        >
          Themes
        </div>
        <div
          className={`item-button ${
            selectedItemType === "product" ? "selected" : ""
          }`}
          onClick={() => handleItemClick("product")}
          style={{
            fontWeight: "bold",
            fontSize: "25px",
          }}
        >
          Solutions
        </div>
      </div>
      <div className="description">
        {selectedItemType === "theme" && <p>{itemDescriptions.theme}</p>}
      </div>

      <div
        className={`carousel-container ${
          selectedItemType === "theme" ? "d-flex" : ""
        }`}
      >
        <div className="carousel-image">
          <img
            src={selectedItemType === "product" ? themeImage : productImage}
            alt="Selected Item"
            style={{ maxWidth: "100%", height: "auto", paddingBottom: "50px" }}
          />
        </div>
        {selectedItemType === "theme" && (
          <div className="text-content" style={{ color: "black" }}>
            <div className="text-line">
              <img
                src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/climateandnaturalhazard.svg"
                style={{ height: "40px" }}
                alt=""
              />
              <h1 className="text-line2">
                Climate & Natural Hazard Risk Analytics
              </h1>
            </div>
            <div className="text-line">
              <img
                src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/environmentalimpact.svg"
                style={{ height: "40px" }}
                alt=""
              />
              <h1 className="text-line2">
                Environmental Impact & Sustainability Analytics
              </h1>
            </div>
            <div className="text-line">
              <img
                src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/groundwaterimpact.svg"
                style={{ height: "40px" }}
                alt=""
              />
              <h1 className="text-line2">Groundwater Impact Assessment</h1>
            </div>
            <div className="text-line">
              <img
                src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/GHGemission.svg"
                style={{ height: "40px" }}
                alt=""
              />
              <h1 className="text-line2">
                GHG Emission & Environmental Social Governance
              </h1>
            </div>
            <div className="text-line">
              <img
                src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/geospatial.svg"
                style={{ height: "40px" }}
                alt=""
              />
              <h1 className="text-line2">Geospatial Tech Integrations</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Image;
