import React, { useState, useEffect } from "react";
import "./CookieBanner.css";

const CookieBanner = () => {
  const [accepted, setAccepted] = useState(
    localStorage.getItem("cookieAccepted") === "true"
  );
  const [cookieAccepted, setCookieAccepted] = useState(
    localStorage.getItem("cookieAccepted") === "true"
  );

  useEffect(() => {
    setCookieAccepted(localStorage.getItem("cookieAccepted") === "true");
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookieAccepted", "true");
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <div className={`cookie-banner ${cookieAccepted ? "" : "cookie-overlay"}`}>
      {cookieAccepted ? (
        <>
          <h1 className="cookie-heading">Please Accept Cookies</h1>
          <p>
            We use ‘cookies’ and related technologies to help identify you and
            your devices, to operate our site, enhance your experience, and
            conduct advertising. You can read more about these uses in our{" "}
            <a href="#">Privacy Policy</a>.
          </p>
          <button onClick={acceptCookies}>Accept Cookies</button>
        </>
      ) : (
        <div className="cookie-overlay-message">
          <h2>Please Accept Cookies</h2>
          <p>
            We use ‘cookies’ and related technologies to help identify you and
            your devices, to operate our site, enhance your experience, and
            conduct advertising. You can read more about these uses in our{" "}
            <a href="#">Privacy Policy</a>.
          </p>
          <button onClick={acceptCookies}>Accept Cookies</button>
        </div>
      )}
    </div>
  );
};

export default CookieBanner;
