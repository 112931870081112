import React from "react";
import "./Products.css";
import Image from "./ProductsImage";

const Products = () => {
  return (
    <>
      <div className="bg-products">
        <div className="text-container">
          <a href="#scroll-section" style={{ textDecoration: "none" }}>
            <p className="scroll-text">SCROLL</p>
          </a>

          <div className="banner-text5">
            <h1 className="banner-heading">Solutions</h1>
            <p className="banner-para5">
              
              GCRS boasts a versatile portfolio of four cutting-edge 
              products, each designed to revolutionize industry standards
            </p>
          </div>
        </div>
      </div>
      <div id="scroll-section">
        <h1 className="product-content">
          GCRS boasts a versatile portfolio of four cutting-edge products, each
          designed to 
          revolutionize industry standards
        </h1>
      </div>

      <div id="scroll-section">
        <div className="components-container">
          <Image />
        </div>
      </div>
    </>
  );
};

export default Products;
