import React from "react";
import "./ContactUs.css";
import ContactUsDes from "./ContactUsDes";

const ContactUs = () => {
  return (
    <>
      <div className="bg-contactUs">
        <div className="image-containerr">
          <a href="#scroll-section" className="scroll-link" style={{ textDecoration: "none" }}>
            <p className="scroll-text" style={{ textDecoration: "none", marginTop: '30rem' }}>
              SCROLL
            </p>
          </a>
          <div className="banner-text8">
            <h1 className="banner-headingg">Contact us</h1>
            <p className="banner-para8">
              <span className="white-text">
                For any general inquiries, please contact us
              </span>
            </p>
          </div>
        </div>

        <div className="contact-container">
          <div className="contact-item1">
            <img
              src={require("../../src/assets/gcrs_images/contactUs_new/mail_white.svg").default}
              className="image-hover"
              alt="Email Icon"
              style={{ marginBottom: 10 }}
            />
            <h3 style={{ color: '#fff' }}>Email Us:</h3>
            <p style={{ color: '#fff' }}>business@gcrs.co.in</p>
            <p style={{ color: '#fff' }}>info@gcrs.co.in</p>
          </div>

          <div className="contact-item">
            <img
              src={require("../../src/assets/gcrs_images/contactUs_new/location_white.svg").default}
              className="image-hover"
              alt="Location Icon"
              style={{ marginBottom: 10 }}
            />
            <h3 style={{ color: '#fff' }}>Find Us</h3>
            <div className="contact-info-content">
              <div className="contact-inf">
                <p style={{ fontSize: "14px", color: '#fff' }}>
                  <a href="#" style={{ color: '#fff', textDecoration: 'none' }}>
                    AP Innovation Valley, Hill-3, IT SEZ, Madhurawada,
                    Visakhapatnam - 530048
                  </a>
                </p>
              </div>
              <div className="vertical-line"></div>
              <div className="contact-inf">
                <p style={{ fontSize: "14px" }}>
                  <a href="#" style={{ color: '#fff', textDecoration: 'none' }}>
                    TP-125, First floor, Technopark@iitk, Phase-I Building Research and Development Centre, Indian Institute of Technology (IIT) Kanpur, Uttar Pradesh – 208016
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="scroll-section">
        <ContactUsDes />
      </div>
    </>
  );
};

export default ContactUs;
