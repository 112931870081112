import React from "react";
import "./OurPrivacyPolicy.css";

const OurPrivacyPolicy = () => {
  return (
    <>
      <div className="bg-privacypolicy">
        <div className="text-container">
          <a href="#scroll-section" style={{ textDecoration: "none" }}>
            <p className="scroll-text">SCROLL</p>
          </a>

          <div className="banner-text3">
            <h1 className="banner-heading"> Privacy Policy</h1>
            <p className="banner-para3"> Our Privacy Terms</p>
          </div>
        </div>
      </div>

      <div id="scroll-section">
        <h1 className="privacy-policy-mainheading">
          Geo Climate Risk Solutions Pvt. Ltd. (GCRS) Privacy Policy
        </h1>
        <h1 className="privacy-policy-sideheading">Introduction</h1>
        <p className="privacy-policy-para">
          Welcome to Geo Climate Risk Solutions Pvt. Ltd. (GCRS). We respect
          your privacy and are committed to protecting your personal data. This
          privacy policy will inform you as to how we look after your personal
          data when you visit our website (regardless of where you visit it
          from) and tell you about your privacy rights and how the law protects
          you.
        </p>
        <h1 className="privacy-policy-sideheading">Data Controller</h1>
        <p className="privacy-policy-para">
          Geo Climate Risk Solutions Pvt. Ltd. is the data controller and
          responsible for your personal data (collectively referred to as
          "GCRS", "we", "us", or "our" in this privacy policy).
        </p>
        <h1 className="privacy-policy-sideheading">
          Information We Collect About You
        </h1>
        <p className="privacy-policy-para">
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).
        </p>
        <p className="privacy-policy-para">
          In particular, we have collected the following categories of personal
          information from our users within the last twelve (12) months:
        </p>
        <div className="privacy-policy-para">
          <ul>
            <li>
              Identity Data includes first name, last name, username or similar
              identifier.
            </li>
          </ul>

          <ul>
            <li>Contact Data includes email address and telephone numbers.</li>
          </ul>

          <ul>
            <li>
              Technical Data includes internet protocol (IP) address, your login
              data, browser type and version, time zone setting and location,
              browser plug-in types and versions, operating system and platform,
              and other technology on the devices you use to access this
              website.
            </li>
            <li>
              Usage Data includes information about how you use our website and
              services.
            </li>
          </ul>
        </div>
        <h1 className="privacy-policy-sideheading">
          How Is Your Personal Data Collected?
        </h1>
        <p className="privacy-policy-para">
          We use different methods to collect data from and about you including
          through:
        </p>
        <div className="privacy-policy-para">
          <ul>
            <li>
              Direct interactions. You may give us your Identity and Contact by
              filling in forms or by corresponding with us by post, phone,
              email, or otherwise.
            </li>
          </ul>

          <ul>
            <li>
              Automated technologies or interactions. As you interact with our
              website, we may automatically collect Technical Data about your
              equipment, browsing actions, and patterns.
            </li>
          </ul>
        </div>
        <h1 className="privacy-policy-sideheading">
          How We Use Your Personal Data
        </h1>
        <p className="privacy-policy-para">
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
        </p>
        <div className="privacy-policy-para">
          <ul>
            <li>
              Where we need to perform the contract we are about to enter into
              or have entered into with you.
            </li>
          </ul>

          <ul>
            <li>
              Where it is necessary for our legitimate interests and your
              interests and fundamental rights do not override those interests.
            </li>
          </ul>

          <ul>
            <li>Where we need to comply with a legal obligation.</li>
          </ul>
        </div>
        <h1 className="privacy-policy-sideheading">
          Disclosure of Your Personal Data
        </h1>
        <p className="privacy-policy-para">
          We may have to share your personal data with the parties set out below
          for the purposes set out in paragraph 4 above.
        </p>
        <div className="privacy-policy-para">
          <ul>
            <li>
              External Third Parties Service providers acting as processors who
              provide IT and system administration services.
            </li>
          </ul>

          <ul>
            <li>
              Professional advisers including lawyers, bankers, auditors, and
              insurers who provide consultancy, banking, legal, insurance, and
              accounting services.
            </li>
          </ul>

          <ul>
            <li>
              Regulators and other authorities who require reporting of
              processing activities in certain circumstances.
            </li>
          </ul>
        </div>
        <h1 className="privacy-policy-sideheading">Data Security</h1>
        <p className="privacy-policy-para">
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used, or accessed in an
          unauthorized way, altered, or disclosed.
        </p>
        <h1 className="privacy-policy-sideheading">Data Retention</h1>
        <p className="privacy-policy-para">
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for, including for the purposes of
          satisfying any legal, accounting, or reporting requirements.
        </p>
        <h1 className="privacy-policy-sideheading">Your Legal Rights</h1>
        <p className="privacy-policy-para">
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data including the right to request
          access, correction, erasure, restriction, transfer, to object to
          processing, to portability of data, and (where the lawful ground of
          processing is consent) to withdraw consent.
        </p>
        <h1 className="privacy-policy-sideheading">Contact Us</h1>
        <p className="privacy-policy-para">
          If you have any questions about this privacy policy or our privacy
          practices, please contact us in the following ways: Full name of legal
          entity: Geo Climate Risk Solutions Pvt. Ltd.
        </p>
        <p className="privacy-policy-para">
          Email address: <a href="mailto:info@gcrs.co.in">info@gcrs.co.in</a>
        </p>
        <p className="privacy-policy-para">
          Postal address: Innovation Valley, Hill-3, IT SEZ, Madhurawada,
          Visakhapatnam - 530048
        </p>
        <h1 className="privacy-policy-sideheading">
          Changes to the Privacy Policy
        </h1>
        <p className="privacy-policy-para" style={{ marginBottom: "3rem" }}>
          We keep our privacy policy under regular review. This version was last
          updated on [Insert Date]. Historic versions can be obtained by
          contacting us.
        </p>
      </div>
    </>
  );
};

export default OurPrivacyPolicy;
