import { useState,useEffect } from "react";
import "./RandD.css";
const RandD = ({ activeTab }) => {
  const isClimateAdaptationTab = activeTab === "Knowledge-support";
  const isPolicyAndStrategyTab = activeTab === "Research-partnerships";
  const isReverHealthTab = activeTab === "Research-projects";
  const isResearchPublicationsthTab = activeTab === "Research-publications";
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div className="randD">
        {isClimateAdaptationTab ? (
          <>
            <h1 style={{ fontSize: "25px" }}> Knowledge Support</h1>

            <p style={{ fontSize: "14px" }}>
              Recognizing the extensive engagement with industry and the
              profound institutional knowledge within our
             {!isMobile && <br />}
              organization, we possess valuable insights that contribute
              significantly to the broader industry. To disseminate{!isMobile && <br />}
              these insights, we operate a dedicated program that involves
              funding and coordination.
            </p>

            <p
              style={{
                fontSize: "14px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Our approach includes sharing knowledge through a variety of
              channels, such as popular and technical publications,
             {!isMobile && <br />}
              digital tools, and strategic partnerships with key industry
              figures. Additionally, we host monthly information sessions,
             {!isMobile && <br />}
              inviting industry leaders for discussions and debates within our
              organization.
            </p>
            <p style={{ fontSize: "14px", marginTop: "30px" }}>
              Our commitment to knowledge dissemination is reflected in the
              targets set by our board, making it a crucial metric{!isMobile && <br />}
              for measuring both individual and group performance. Notably, our
              recent articles have found their place in industry
             {!isMobile && <br />}
              journals, newsletters, scientific publications, and across our
              social media platforms.
            </p>
          </>
        ) : isPolicyAndStrategyTab ? (
          <>
            <h1 style={{ fontSize: "25px" }}>Research Partnerships</h1>
            <p style={{ fontSize: "14px", marginTop: "20px" }}>
              Active involvement in research is integral to our mission. It
              ensures that our staff, spanning all sectors of the organization,
             {!isMobile && <br />}
              are exposed to the latest and most innovative ideas.
              Simultaneously, it enables researchers to synchronize and
              implement
             {!isMobile && <br />}
              projects tailored to the specific requirements of managers and
              policymakers. We foster this engagement through diverse{!isMobile && <br />}
              channels, such as authoring research papers, offering assistance
              to staff interested in research, co-supervising students,
             {!isMobile && <br />}
              welcoming research interns, and allocating resources to support
              our research priorities.
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={require("../../src/assets/gcrs_images/RandD_icons/mekorot.png")}
                className="r-d-styles"
              />
              <img
                src={require("../../src/assets/gcrs_images/RandD_icons/IIT_Kanpur_Logo 1.png")}
                className="r-d-styles"
              />
            </div>
          </>
        ) : isReverHealthTab ? (
          <>
            <h1 style={{ fontSize: "25px" }}>Research projects</h1>
            <h1
              style={{
                fontSize: "18px",
                marginTop: "20px",
                fontWeight: "bold",
              }}
            >
              Advanced Geo-Hazard Assessment for Hydrocarbon Pipeline Integrity
            </h1>
            <p style={{ fontSize: "14px" }}>
              At the core of our proficiency lies in the realm of stream
              restoration and channel design, where our achievements
             {!isMobile && <br />}
              are rooted in a collaborative partnership with nature. By deeply
              understanding the evolutionary trajectory of stream systems,
             {!isMobile && <br />}
              we formulate solutions that seamlessly integrate with natural
              processes. This approach results in streamlined designs that not{" "}
             {!isMobile && <br />}
              only adhere to the principles of efficiency but also yield the
              desired outcomes.
            </p>
            <a
              href=" https://pngrb.gov.in/pdf/press-note/GAIL15062022/GHHP.pdf"
              id="accred_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://pngrb.gov.in/pdf/press-note/GAIL15062022/GHHP.pdf
            </a>
            <h1
              style={{
                fontSize: "18px",
                marginTop: "20px",
                fontWeight: "bold",
              }}
            >
              Climate Resilience in Tea Production: A Research Initiative by
              Fairtrade
             {!isMobile && <br />}
              NAPP and Geo Climate Risk Solutions
            </h1>
            <p style={{ fontSize: "14px" }}>
              This research project, a collaborative effort between Fairtrade
              NAPP and Geo Climate Risk Solutions, delves into the{!isMobile && <br />}
              climate change impacts and greenhouse gas emissions in four key
              Indian tea estates. Employing a blend of field data,
             {!isMobile && <br />}
              analytical tools, and climate modeling, the initiative seeks to
              understand and mitigate the environmental challenges
             {!isMobile && <br />}
              faced by the tea sector. The project's findings are instrumental
              in shaping sustainable tea cultivation practices,
             {!isMobile && <br />}
              emphasizing carbon management and climate adaptation strategies.
            </p>
            <a
              href="https://www.fairtradenapp.org/collaboration-with-geo-climate-risk-solutions-pvt-ltd-climate-change-impact-and-ghg-emissions-in-4-tea-estates-and-providing-recommendations-for-adaptation-and-mitigation/"
              id="accred_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              hhttps://www.fairtradenapp.org/collaboration-with-geo-climate-risk-solutions-pvt-ltd-climate-change-impact
             {!isMobile && <br />}
              -and-ghg-emissions-in-4-tea-estates-and-providing-recommendations-for-adaptation-and-mitigation/
            </a>
          </>
        ) : isResearchPublicationsthTab ? (
          <>
            <h1 style={{ fontSize: "25px" }}>Research Publications</h1>

            <p style={{ fontSize: "14px", marginTop: "30px" }}>
              Joshi, S.K., Swarnkar, S., Shukla, S. et al. Snow/Ice Melt,
              Precipitation, and Groundwater Contribute to the Sutlej River
              System.
             {!isMobile && <br />}
               Water Air Soil Pollut 234, 719 (2023).
              https://doi.org/10.1007/s11270-023-06744-4
            </p>
            <a
              href="https://link.springer.com/article/10.1007/s11270-023-06744-4#citeas"
              id="accred_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://link.springer.com/article/10.1007/s11270-023-06744-4#citeas
            </a>

            <p
              style={{
                fontSize: "14px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Hamidi, M.D., Gröcke, D.R., Joshi, S.K. and Greenwell, H.C., 2023.
              Investigating groundwater recharge using hydrogen and{!isMobile && <br />}
              oxygen stable isotopes in Kabul city, a semi-arid region. Journal
              of Hydrology, 626, p.130187.
            </p>
            <a
              href="https://www.sciencedirect.com/science/article/pii/S0022169423011290"
              id="accred_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.sciencedirect.com/science/article/pii/S0022169423011290
            </a>
            <p
              style={{
                fontSize: "14px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Joshi, S.K., Kumar, S., Sinha, R., Rai, S.P., Khobragade, S. and
              Rao, M.S., 2023. Identifying moisture transport pathways
             {!isMobile && <br />}
              for north‐west India. Geological Journal.
            </p>
            <a
              href="https://onlinelibrary.wiley.com/doi/abs/10.1002/gj.4759"
              id="accred_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://onlinelibrary.wiley.com/doi/abs/10.1002/gj.4759
            </a>
            <p
              style={{
                fontSize: "14px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Beg, Z., Joshi, S.K., Singh, D., Kumar, S. and Gaurav, K., 2022.
              Surface water and groundwater interaction in the Kosi River{!isMobile && <br />}
              alluvial fan of the Himalayan Foreland. Environmental Monitoring
              and Assessment, 194(8), p.556.
            </p>
            <a
              href="https://link.springer.com/article/10.1007/s10661-022-10192-8"
              id="accred_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://link.springer.com/article/10.1007/s10661-022-10192-8
            </a>
            <p
              style={{
                fontSize: "14px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Swarnkar, S., Prakash, S., Joshi, S.K. and Sinha, R., 2021.
              Spatio-temporal rainfall trends in the Ganga River basin over the
              last
             {!isMobile && <br />}
              century: understanding feedback and hydrological
              impacts. Hydrological Sciences Journal, 66(14), pp.2074-2088.
            </p>
            <a
              href="https://www.tandfonline.com/doi/abs/10.1080/02626667.2021.1976783"
              id="accred_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.tandfonline.com/doi/abs/10.1080/02626667.2021.1976783
            </a>
            <p
              style={{
                fontSize: "14px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Joshi, S.K. and Gautam, S., 2021. Understanding spatio-temporal
              variability of groundwater level changes in India using
              hydrogeological
             {!isMobile && <br />}
              and GIS techniques. In Bow Ties in Process Safety and
              Environmental Management (pp. 219-230). CRC Press.
            </p>
            <a
              href="https://www.taylorfrancis.com/chapters/edit/10.1201/9781003140382-13/understanding-spatio-temporal-variability-groundwater-level-changes-india-using-hydrogeological-gis-techniques-suneel-kumar-joshi-sneha-gautam"
              id="accred_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.taylorfrancis.com/chapters/edit/10.1201/9781003140382-13/understanding-spatio-temporal-
             {!isMobile && <br />}
              variability-groundwater-level-changes-india-using-hydrogeological-gis-techniques-suneel-kumar-joshi-sneha-gautam
            </a>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default RandD;
