import React, { useState, useEffect, useRef } from "react";
import "./capDescription.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TabComponent = ({ setActiveTab }) => {
  const [activeTab, setActiveTabState] = useState("climate-adaptation");
  const [isSmallOrMediumScreen, setIsSmallOrMediumScreen] = useState(
    window.innerWidth < 768
  );
  const carouselRef = useRef(null);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setActiveTabState(tabId);
  };

  const tabData = [
    {
      id: "climate-adaptation",
      title: "Climate action",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/climateadaption.svg")
          .default,
    },
    {
      id: "policy-and-strategy",
      title: "Program and policy frameworks",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/policy_strategy.svg")
          .default,
    },
    {
      id: "River Health",
      title: "Water bodies health assessment",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/riverhealth.svg")
          .default,
    },
    {
      id: "Urban Water",
      title: "Integrated urban water management",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/urbanwater.svg")
          .default,
    },
    {
      id: "Coastal Management",
      title: "Coastal areas protection and management",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/coastalmanagement.svg")
          .default,
    },
    {
      id: "Water Resource Management",
      title: " Environmental water management",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/waterresourcemanagement.svg")
          .default,
    },
    {
      id: "Economics",
      title: "Socio-economic impact assessment",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/economics.svg")
          .default,
    },
    {
      id: "Cities & Communities",
      title: " Urban spaces and inhabitants",
      iconSrc: require("../../src/assets/gcrs_images/Capabilities/cities.svg")
        .default,
    },
    {
      id: "Evaluation",
      title: "Assessment",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/evaluation.svg")
          .default,
    },

    {
      id: "Landscape Ecology",
      title: "Environmental ecology",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/landscapeecology.svg")
          .default,
    },
    {
      id: "Disaster risk, resilience & recovery",
      title: " Disaster risk reduction",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/disasterrisk.svg")
          .default,
    },
    {
      id: "Accreditation",
      title: "Accreditation",
      iconSrc:
        require("../../src/assets/gcrs_images/Capabilities/accreditation1.svg")
          .default,
    },
  ];
  useEffect(() => {
    const handleResize = () => {
      setIsSmallOrMediumScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCarouselChange = (index) => {
    const selectedTab = tabData[index].id;
    setActiveTab(selectedTab);
    setActiveTabState(selectedTab);
  };

  const handleLeftArrowClick = () => {
    const currentIndex = tabData.findIndex((tab) => tab.id === activeTab);
    const newIndex = currentIndex === 0 ? tabData.length - 1 : currentIndex - 1;
    const selectedTab = tabData[newIndex].id;
    setActiveTab(selectedTab);
    setActiveTabState(selectedTab);
    carouselRef.current.selectItem(newIndex);
  };

  const handleRightArrowClick = () => {
    const currentIndex = tabData.findIndex((tab) => tab.id === activeTab);
    const newIndex = currentIndex === tabData.length - 1 ? 0 : currentIndex + 1;
    const selectedTab = tabData[newIndex].id;
    setActiveTab(selectedTab);
    setActiveTabState(selectedTab);
    carouselRef.current.selectItem(newIndex);
  };

  return (
    <div className="row">
      {isSmallOrMediumScreen ? (
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={tabData.findIndex((tab) => tab.id === activeTab)}
          className="custom-carousel1"
          onChange={handleCarouselChange}
          ref={carouselRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {tabData.map((tab) => (
            <div
              key={tab.id}
              className={` ${activeTab === tab.id ? "active" : ""}`}
            >
              <a
                href={`#${tab.id}`}
                data-id={tab.id}
                className={`tab-link ${activeTab === tab.id ? "active" : ""}`}
                onClick={() => handleTabClick(tab.id)}
                onTouchStart={() => handleTabClick(tab.id)}
              >
                <div
                  className={`tab-content-container ${
                    activeTab === tab.id ? "active" : ""
                  }`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <span
                    className={`tab-link-icon ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                  >
                    <img
                      src={tab.iconSrc}
                      alt={tab.title}
                      style={{ height: "25px" }}
                    />
                  </span>
                  <span
                    className={`tab-link-title ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    style={{ color: "#000", fontSize: "13px" }}
                  >
                    {tab.title}
                  </span>
                </div>
              </a>
            </div>
          ))}
        </Carousel>
      ) : (
        <section
          id="custom-tab-content"
          className="full-width"
          style={{ marginBottom: "20px", width: "70%" }}
        >
          <div className="custom-tab-content-wrapper">
            <div className="custom-tabs-link carousel">
              {tabData.map((tab) => (
                <div
                  key={tab.id}
                  className={`${activeTab === tab.id ? "active" : ""}`}
                >
                  <a
                    href={`#${tab.id}`}
                    data-id={tab.id}
                    className={`tab-link ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    onClick={() => handleTabClick(tab.id)}
                    onTouchStart={() => handleTabClick(tab.id)}
                  >
                    <div
                      className={`tab-content-container ${
                        activeTab === tab.id ? "active" : ""
                      }`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <span
                        className={`tab-link-icon ${
                          activeTab === tab.id ? "active" : ""
                        }`}
                      >
                        <img
                          src={tab.iconSrc}
                          alt={tab.title}
                          style={{ height: "25px" }}
                        />
                      </span>
                      <span
                        className={`tab-link-title ${
                          activeTab === tab.id ? "active" : ""
                        }`}
                        style={{ color: "#000", fontSize: "13px" }}
                      >
                        {tab.title}
                      </span>
                    </div>
                  </a>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default TabComponent;
