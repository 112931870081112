import React, { useState, useEffect } from "react";
import "./ProductsImage.css";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function Image() {
  const [selectedItemType, setSelectedItemType] = useState("theme");
  const [clickedItem, setClickedItem] = useState(null);
  const [activeTab, setActiveTab] = useState(null);

  const handleItemClickResponsive = (itemType) => {
    setSelectedItemType(itemType);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setSelectedItemType(tabId);
  };

  const isSmallOrMediumScreen = window.innerWidth <= 768;

  const handleItemClick = (type) => {
    setSelectedItemType(type);
    setClickedItem(type);
  };

  const getItemButtonStyle = (type) => {
    return clickedItem === type ? "selected" : "";
  };

  const tabData = [
    {
      id: "theme",
      iconSrc:
        "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Group 50.png",
    },
    {
      id: "product",
      iconSrc:
        "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Group 51.png",
    },
    {
      id: "geosust",
      iconSrc:
        "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Group 52.png",
    },
    {
      id: "geocoast",
      iconSrc:
        "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Group 53.png",
    },
  ];

  const itemDescriptions = {};

  const productImage =
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Group 58.png";
  const themeImage =
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Group 59.png";
  const geosustImage =
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Group 61.png";
  const geocoastImage =
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Group 60.png";

  const videoUrl = {
    theme:
      "https://www.youtube.com/watch?v=FMrq0nfy1iA&ab_channel=GCRS-forsustainability",
    product:
      "https://www.youtube.com/watch?v=lr3SEhgzmEU&ab_channel=GCRS-forsustainability",
    geosust:
      "https://www.youtube.com/watch?v=Jcn6PkkHEdk&ab_channel=GCRS-forsustainability",
    geocoast: "Video URL for Geocoast",
  };

  const getContent = () => {
    if (selectedItemType === "theme") {
      return (
        <div name={"text content"}>
          <div className="text">
            <h1
              style={{
                fontSize: "20px",
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              To Know your Lake Health!
            </h1>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Ellipse 7.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Assess the lake/water body health status in terms of
              
              physical attribute Degradation Index, Water
            
              area and terrestrial vegetation area
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Ellipse 7.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Assess the lake/water body health status in terms of
             
              Water Quality Degradation Index comprising of 
              parameters Colour Dissolved Organic Matter, Electrical 
              Conductivity and Green-Red Vegetation Index
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Ellipse 7.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Continous monitoring and update of waterbody inundation
              
              and its dynamics as specified by its user
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Ellipse 7.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Predicting the recovery potential of the lake/waterbody
             
              and get tips to restore it
            </p>
          </div>
        </div>
      );
    } else if (selectedItemType === "product") {
      return (
        <div name={"text content"}>
          <div className="text">
            <h1
              style={{
                fontSize: "20px",
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              Get a Comprehensive analysis & reports of Carbon
             
              Offsetting
            </h1>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Carbon Exchange 2.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Platform for carbon neutralization by connecting carbon 
              emitters and carbon sequesters.
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Carbon Exchange 2.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px",width:'90%' }}>
              Plantation Cover Analysis report indicating to health 
              status, phenological changes based on multi-temporal
              
              geospatial data analytics.
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Carbon Exchange 2.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Get a continuous monitoring on plantation health status
            
              and growth stages through carbon exchange web 
              application platform
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Carbon Exchange 2.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              {" "}
              In detailed feild plot enumeration using validated and
              
              robust feild equipment and mobile application to
            
              capture tree count, species, DBH, height etc..
            </p>
          </div>
        </div>
      );
    } else if (selectedItemType === "geosust") {
      return (
        <div name={"text content"}>
          <div className="text">
            <h1
              style={{
                fontSize: "20px",
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              Know your Infrastructure Integrity!
            </h1>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/geosust_logo 1.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Help to identify the potential geohazard and climate 
              risks along critical insfrastructure
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/geosust_logo 1.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Get in detailed assessment on encroachment using 
              multi temporal geospatial data.
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/geosust_logo 1.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Enhance the critical infrastructure performance & 
              integrity with real-time insights natural hazard risk
             
              and spatial analytics.
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/geosust_logo 1.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Allow better collaboration and visualization by centralizing
             
              asset performance data.
            </p>
          </div>
        </div>
      );
    } else if (selectedItemType === "geocoast") {
      return (
        <div name={"text content"}>
          <div className="text">
            <h1
              style={{
                fontSize: "20px",
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              Exploring the senergy of Coastal & Marine environment
            </h1>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Layer 2.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Evaluating the marine pollution levels in order to implement
              
              strategies for a cleaner and healthier marine ecosystem.
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Layer 2.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Assess real-time monitoring and historical ship traffic 
              and oil spill analysis.
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Layer 2.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Suitability analysis of offshore wind energy potential zone.
            </p>
          </div>
          <div className="text">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Products/Layer 2.png"
              style={{ height: "20px", marginTop: "17px", marginRight: "10px" }}
              alt=""
            />
            <p style={{ fontSize: "15px", marginTop: "12px" ,width:'90%'}}>
              Efficiently Track and assess shoreline change monitoring
              
              overtime
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    setActiveTab(selectedItemType);

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setActiveTab(selectedItemType);
      } else {
        setActiveTab(null);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [selectedItemType]);

  const toggleSelectedItem = () => {
    if (selectedItemType === "theme") {
      setSelectedItemType("product");
    } else if (selectedItemType === "product") {
      setSelectedItemType("geosust");
    } else if (selectedItemType === "geosust") {
      setSelectedItemType("geocoast");
    } else {
      setSelectedItemType("theme");
    }
  };

  return (
    <div className="container" style={{ marginBottom: "30px" }}>
      <div
        className={` ${
          selectedItemType || (activeTab && !isSmallOrMediumScreen)
        }`}
      ></div>
      {isSmallOrMediumScreen ? (
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={tabData.findIndex((tab) => tab.id === activeTab)}
          className="custom-carousel1"
          onChange={(newSelectedItem) =>
            handleTabClick(tabData[newSelectedItem].id)
          }
        >
          {tabData.map((tab) => (
            <div
              key={tab.id}
              className={`tab-content-container ${
                activeTab === tab.id ? "active" : ""
              }`}
            >
              <span
                className={`tab-link-icon-product ${
                  activeTab === tab.id ? "active" : ""
                }`}
              >
                <img
                  src={tab.iconSrc}
                  alt={tab.title}
                  style={{ height: "40px", width: "120px" }}
                />
              </span>
              <span
                className={`tab-link-title ${
                  activeTab === tab.id ? "active" : ""
                }`}
                style={{ color: "#000", fontSize: "13px" }}
              >
                {tab.title}
              </span>
            </div>
          ))}
        </Carousel>
      ) : (
        <>
          <div
            className={`hr-line ${
              selectedItemType || (activeTab && !isSmallOrMediumScreen)
            }`}
            onClick={toggleSelectedItem}
          ></div>
          <div className="item-buttons1">
            {tabData.map((tab) => (
              <div
                key={tab.id}
                className={`item-button ${getItemButtonStyle(tab.id)}`}
                onClick={() => handleItemClick(tab.id)}
                style={{
                  fontWeight: "bold",
                  fontSize: "25px",
                  cursor: "pointer",
                  marginRight: "100px",
                }}
              >
                <img
                  src={tab.iconSrc}
                  alt={tab.title}
                  style={{
                    width: "150px",
                    transform: clickedItem === tab.id ? "scale(1.5)" : "none",
                    transition: "transform 0.3s ease",
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
      <div className="description">
        {selectedItemType && <p>{itemDescriptions[selectedItemType]}</p>}
      </div>
      <div className={`carousel-container ${selectedItemType ? "d-flex" : ""}`}>
        <div className="carousel-image">
          <img
            src={
              selectedItemType === "theme"
                ? themeImage
                : selectedItemType === "product"
                ? productImage
                : selectedItemType === "geosust"
                ? geosustImage
                : selectedItemType === "geocoast"
                ? geocoastImage
                : null
            }
            alt="Selected Item"
          />
        </div>
        {getContent()}
      </div>
      {selectedItemType === "theme" && (
        <a
          href="https://lamas.co.in/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontSize: "20px", marginTop: "25px" }}
        >
          https://lamas.co.in/
        </a>
      )}
      {selectedItemType === "product" && (
        <a
          href="https://carbonexchange.co.in/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontSize: "20px", marginTop: "25px" }}
        >
          https://carbonexchange.co.in/
        </a>
      )}
      {selectedItemType === "geosust" && (
        <a
          href="https://geosust.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontSize: "20px", marginTop: "25px" }}
        >
          https://geosust.com/
        </a>
      )}
      {selectedItemType === "geocoast" && (
        <a
          href="https://geosust.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontSize: "20px", marginTop: "25px" }}
        >
          https://geocoast.co.in/
        </a>
      )}
      <div className="video-container">
        {selectedItemType && <ReactPlayer url={videoUrl[selectedItemType]} />}
      </div>
    </div>
  );
}

export default Image;
