import React from "react";
import "./OurJourney.css";

const Clients = () => {
  return (
    <div className="bg-text">
      <h1 className="main-heading">Our Journey</h1>
      <h1 className="sub-heading">
        Guided by our vision and unwavering values, 
        our business has evolved to offer trusted 
        advice and industry leadership
      </h1>

      <p className="paragraph">
        GCRS was established in the year 2014. We began our work in the
        environment and sustainability domains and finally
        
        rose to the top. Several gems have been tied to our string over the
        years in the shape of our accomplishments, clientele, 
        and an exceptional team of professionals. This was only possible due to
        our unwavering commitment to addressing 
        environmental issues and making the world a better and greener place to
        live.
      </p>
      <div className="image-container">
        <img
          src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/GCRS_journey_3.png"
          alt="Our Journey"
          className="journey-image"
        />
      </div>
      <div className="image-grid">
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle%20140.png" />
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 141.png" />
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 142.png" />
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 144.png" />
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 145.png" />
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 143.png" />
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 146.png" />
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 147.png" />
      </div>
    </div>
  );
};

export default Clients;
