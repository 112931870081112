import React from "react";
import "./AboutUs.css";
import Image from "./aboutusImage";

const AboutUs = () => {
  return (
    <>
      <div className="bg-aboutus">
        <div className="text-container">
          <a href="#scroll-section" style={{ textDecoration: "none" }}>
            <p className="scroll-text">SCROLL</p>
          </a>

          <div className="banner-text2">
            <h1 className="banner-heading"> About Us</h1>
            <p className="banner-para2" >
              We collaborate with governments and businesses to address
              intricate challenges pertaining to the natural and man made
              environment
            </p>
          </div>
        </div>
      </div>
      <div id="scroll-section">
        <div>
          <h1 className="about1">
            Geo Climate Risk Solutions Pvt Ltd (GCRS) is a start-up company
            working 
            in the area of environment and risk assessment. We are a solution
            provider,
         
            consultancy and advisory services organization primarily focusing on
            challenges
         
            related to environment and sustainability and offering solutions to
            governments,
            
            institutions, corporates, industries, multi-lateral, bilateral
            funding partners
         
            and donor agencies, and non-governmental organizations
          </h1>
          <p className="about2">
            GCRS is an accredited company by Central Ground Water Authority to
            carry out Hydrogeological Reporting, Groundwater 
            Modelling, Groundwater Impact Analysis for Industries,
            Infrastructure, Mining Sector, and Institutions.
          </p>
          <p className="about2">
            GAIL (India) Ltd., a major Government of India Public Sector company
            in hydrocarbons, has invested in our company by holding a
            10% stake and also enlisted us in carrying out Geo-Hazard Assessment
            & Management along Hydrocarbon Pipelines. We have an 
            association with IIT Kanpur for R & D and product development. We do
            have an MOU with Mekorot, National Water Company of
            
            Israel.
          </p>
          <p className="about2">
            GCRS derives its strength from a pool of highly experienced human
            resources with in depth knowledge on issues related to 
            environmental risks, management of natural resources, sustainability
            safeguards, policy framework, socio-economic analysis and
            
            capacity building.
          </p>
          <h1 className="about1">Delivery Model:</h1>
          <p className="about2">
            The GCRS ecosystem harnesses a collective array of talents and
            assets, meticulously tailored to assist clients in addressing
            intricate,
           
            systemic dilemmas
            confronting our society and environment. Our expertise
            extends over three fundamental fields: Environmental, Industrial and
            Social
           
            Sustainability
          </p>
        </div>
      </div>
      <div id="scroll-section">
        <div className="items-container">
          <Image />
        </div>
      </div>
    </>
  );
};
export default AboutUs;
