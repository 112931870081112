import React from "react";
import "./Careers.css";
const Careers = () => {
  return (
    <>
      <div className="bg-careers">
        <div className="text-containerr">
          <a href="#scroll-section" style={{ textDecoration: "none" }}>
          
          </a>

          <div className="banner-textt">
            <h1 className="banner-headingc"> Careers</h1>
            <p className="banner-parac">
              We enlist top engineers, scientists, and economists to
               hope our natural and urban environment's future:
              <span style={{ color: "#FCB22F" }}>careers@gcrs.co.in</span>{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
