import React from "react";
import "./InvestorsPartners.css";

const InvestorsPartners = () => {
  return (
    <>
      {/* <div className="image-icons">
        <img
          src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/InvestorsIcons/Memberr.svg"
          alt="invested"
          className="icon-image"
        />
        <img
          src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/InvestorsIcons/Watershewardship.svg"
          alt="member"
          className="icon-image"
        />
        <img
          src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/InvestorsIcons/Gail_Logo.svg"
          alt="gail"
          className="icon-image"
        />
        <img
          src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/InvestorsIcons/CGWA.svg"
          alt="CGWA"
          className="icon-image"
        />
         <img
          src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/InvestorsIcons/IWA.svg"
          alt="CGWA"
          className="icon-image"
        />
      </div> */}
      <div className="invest-partner">
        <div className="section">
          <h1 className="investpartner-heading">Our Investors</h1>
          <p className="descrip-text">
            Backed by renowned investors, our commitment to delivering optimal
            environmental solutions is reinforced by unwavering support
          </p>
          <div className="image-row">
            <img src={require("../../src/assets/Clients/Gail.png")} alt="Gail" className="partner-image" />
          </div>
        </div>
        <div className="section">
          <h1 className="investpartner-heading">Our Partners</h1>
          <p className="descrip-text">
            GCRS and Synspective join forces in an impactful partnership to offer
            SAR satellite-based risk analysis solutions across South Asia
          </p>
          <div className="image-row">
            <img src={require("../../src/assets/Clients/Synspective.png")} alt="Synspective" className="partner-image" />
            <img src={require("../../src/assets/Clients/JBA1.png")} alt="JBA1" className="partner-image" />
            <img src={require("../../src/assets/Clients/clients1.png")} alt="clients1" className="partner-image" />
            <img src={require("../../src/assets/Clients/JBA.png")} alt="JBA" className="partner-image" />
            <img src={require("../../src/assets/Clients/clients2.png")} alt="clients2" className="partner-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorsPartners;
