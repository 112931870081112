import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Commitments.css";
import TabComponent from "./commDescription";
import Comma from "./Comma";

const Commitments = () => {
  const [activeTab, setActiveTab] = useState("Environment");
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam === "womenEmpowerment") {
      setActiveTab("Women Empowerment");
    }
  }, [location.search]);

  return (
    <>
      <div className="bg-commitments">
        <div className="text-container">
          <a href="#scroll-section" style={{ textDecoration: "none" }}>
            <p className="scroll-text">SCROLL</p>
          </a>

          <div className="banner-text6">
            <h1 className="banner-heading"> Commitments</h1>
            <p className="banner-para6">
             
              We are dedicated to implementing best business practices and
              operational procedures across our organization, involving our
              employees, stakeholders, and clients
            </p>
          </div>
        </div>
      </div>
      {/* <div id="scroll-section">
    <div className='components-container'>
    <div style={{marginTop:"80px"}}>
    <TabComponent setActiveTab={setActiveTab}  />
    </div>
        <Comma activeTab={activeTab}/>
    </div>
    </div> */}

      <div id="scroll-section">
        <div className="components-container">
          <div style={{ marginTop: "80px" }}>
            <TabComponent setActiveTab={setActiveTab} />
          </div>
          <div className="comma-container">
            <div className="comma-large-screen">
              <Comma activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>

        <div className="comma-small-medium-screen">
          <Comma activeTab={activeTab} />
        </div>
      </div>
    </>
  );
};

export default Commitments;
