import React from "react";
import "./RandDintiatives.css";
import RandDdes from "./RandDdes";

import { useState } from "react";
import RandD from "./RandD";

const RandDintiatives = () => {
  const [activeTab, setActiveTab] = useState("Knowledge-support");

  return (
    <>
      <div className="bg-RandD">
        <div className="text-container">
          <a href="#scroll-section" style={{ textDecoration: "none" }}>
            <p className="scroll-text">SCROLL</p>
          </a>

          <div className="banner-text7">
            <h1 className="banner-heading"> R&D Program</h1>
            <p className="banner-para7">
              Our research and development program positions us as vital
              intermediaries connecting the latest research findings 
              with our clients
            </p>
          </div>
        </div>
      </div>
      <div id="scroll-section">
        <div>
          <div className="components-container">
            <div style={{ marginTop: "80px" }}>
              <RandDdes setActiveTab={setActiveTab} />
            </div>
            <div className="comma-container">
              <div className="comma-large-screen">
                <RandD activeTab={activeTab} />
              </div>
            </div>
          </div>
          <div className="comma-small-medium-screen">
            <RandD activeTab={activeTab} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RandDintiatives;
