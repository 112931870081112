import React from "react";

function TeamMember({ name, position, imageSrc, linkedinUrl }) {
  return (
    <div className="team-member">
      <div className="image-container">
        <img className="team-member-image" src={imageSrc} alt={name} />
        <div className="overlay">
          <p className="name">{name}</p>
          <p>{position}</p>
        </div>
        <a href={linkedinUrl} className="linkedin-icon">
          <img
            src={require("../../src/assets/gcrs_images/linkedin.svg").default}
            alt="LinkedIn Profile"
          />
        </a>
      </div>
    </div>
  );
}

export default TeamMember;
