import React, { useState ,useEffect} from "react";
import "./Comma.css";
const Comma = ({ activeTab, setActiveTab }) => {
  // const isClimateAdaptationTab = activeTab === 'BCorp';
  // const isPolicyAndStrategyTab = activeTab === 'RAP';
  const isReverHealthTab = activeTab === "Environment";
  const isUrbanWaterTab = activeTab === "Water footprint";
  const isCoastelManagementTab = activeTab === "Supply chain";
  const isAccreditationTab = activeTab === "Accreditation";
  const isSdgTab = activeTab === "Sdg";
  const isWomenEmpowermentTab = activeTab === "Women Empowerment";
  const [isImageVisible, setImageVisible] = useState(false);

  const toggleImage = () => {
    // if (!isWomenEmpowermentTab) {
    setActiveTab("Women Empowerment");
    setImageVisible(!isImageVisible);
  };

  const handleWomenEmpowermentClick = (event) => {
    event.preventDefault();
    toggleImage();
  };

  const hideImage = () => {
    setImageVisible(false);
  };
  const containerStyle = {
    height: isImageVisible ? "auto" : "150px",
    overflow: "hidden",
    transition: "height 0.3s ease",
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="commaw">
      {isReverHealthTab ? (
        <>
           <h1 style={{ fontSize: '25px' }}>Environment</h1>
      <p style={{ fontSize: '14px' }}>
        Our central mission is centered on fostering a positive impact on
        the planet, and each project we embark
        {!isMobile && <br />}
        upon is geared towards generating a net benefit for the environment.
        Guided by a simple philosophy, we
        {!isMobile && <br />}
        only engage in work that contributes to a net positive impact.
      </p>
      <p style={{ fontSize: '14px', marginTop: '20px', marginBottom: '20px' }}>
        Our commitment to transparency is reflected in the provision of
        annual reports to both our staff and shareholders.
        {!isMobile && <br />}
        These reports detail the nature and scope of our projects, ensuring
        clear communication about our initiatives.
      </p>
      <p style={{ fontSize: '14px', marginTop: '20px' }}>
        Recognizing our environmental footprint, we conscientiously address
        the impact of our regular internal operations
        {!isMobile && <br />}
        and uphold our dedication to environmental preservation across all
        facets of our endeavours.
      </p>
        </>
      ) : isUrbanWaterTab ? (
        <>
          <h1 style={{ fontSize: '25px' }}>Water Footprint</h1>
      <p style={{ fontSize: '14px' }}>
        Harnessing the power of remote sensing and Geographic Information
        Systems (GIS), our organization is actively
        {!isMobile && <br />}
        engaged in quantifying and managing its water footprint for
        sustainability. By employing cutting-edge technologies,
        {!isMobile && <br />}
        we can remotely monitor water usage patterns, identify potential
        areas of concern, and implement targeted strategies
        {!isMobile && <br />}
        to optimize water resources. Through the integration of satellite
        imagery and GIS mapping, we gain a comprehensive
        {!isMobile && <br />}
        understanding of our water consumption across different operational
        facets.
      </p>

      <p style={{ fontSize: '14px', marginTop: '20px', marginBottom: '20px' }}>
        This approach allows us to pinpoint inefficiencies, track changes
        over time, and make informed decisions to reduce our
        {!isMobile && <br />}
        overall water impact. Our commitment to leveraging these advanced
        tools underscores our dedication to environmentally
        {!isMobile && <br />}
        responsible practices, ensuring a more sustainable and conscientious
        use of water resources in alignment with global
        {!isMobile && <br />}
        conservation goals.
      </p>
        </>
      ) : isCoastelManagementTab ? (
        <>
          <h1 style={{ fontSize: '25px' }}>Supply Chain</h1>
      <p style={{ fontSize: '14px', marginTop: '20px' }}>
        We adopt a proactive strategy to minimize risks and mitigate impacts
        within our supply chain. Our dedication extends
        {!isMobile && <br />}
        to partnering with third parties who share values aligned with our
        own. This collaboration encompasses joint venture
        {!isMobile && <br />}
        and alliance partners, clients, sub-consultants, and suppliers.
      </p>
      <p style={{ fontSize: '14px', marginTop: '20px', marginBottom: '20px' }}>
        In conjunction with our clients, partners, and collaborators,
        including sub-consultants, we actively work to eradicate
        {!isMobile && <br />}
        the presence of modern slavery in our supply chain. Our procurement
        practices adhere to rigorous standards, guided
        {!isMobile && <br />}
        by a vision aligned with ISO 20400. We aim to seamlessly integrate
        sustainability into our procurement processes.
      </p>
        </>
      ) : isWomenEmpowermentTab ? (
        <>
           <h1 style={{ fontSize: '25px' }}>Women Empowerment</h1>
      <p style={{ fontSize: '14px', marginTop: '30px' }}>
        At Geo Climate Risk Solutions (GCRS), we are committed to fostering
        women's empowerment in every aspect of our business.
        {!isMobile && <br />}
        Recognising the vital role of women in achieving sustainable
        development, we actively support the Women's Empowerment Principles.
        {!isMobile && <br />}
        Our initiatives are focused on creating equal opportunities for
        women, ensuring their representation in leadership roles, and
        promoting
        {!isMobile && <br />}
        gender equality in the workplace. We believe that empowering women
        is not only a moral imperative but also a key driver of business
        {!isMobile && <br />}
        innovation and success. Join us in this crucial journey towards a
        more inclusive and equitable future.
      </p>
          <a
            href="/commitments?tab=womenEmpowerment"
            id="Women Empowerment"
            // target="_blank"
            rel="noopener noreferrer"
            onClick={toggleImage}
          >
            {isImageVisible
              ? "Read our full Statement of Support here"
              : "Read our full Statement of Support here"}
          </a>

          {!isImageVisible && (
            <img
              src={require("../../src/assets/gcrs_images/comm_icons/down-arrow (1).png")}
              alt="Down Arrow"
              style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }}
              onClick={toggleImage}
            />
          )}

          {isImageVisible && (
            <img
              src={require("../../src/assets/gcrs_images/comm_icons/up-arrow.png")}
              alt="Up Arrow"
              style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }}
              onClick={hideImage}
            />
          )}

          {isImageVisible && (
            <div>
              <img
                src={require("../../src/assets/gcrs_images/comm_icons/CEO_Statement_of_Support by Geo Climate Risk Solutions Pvt Ltd-1.png")}
                alt="CEO Statement of Support"
                style={{ width: "80%" }}
              />
            </div>
          )}
        </>
      ) : isSdgTab ? (
        <>
          <h1 style={{ fontSize: '25px' }}>
        Embracing the Sustainable Development Goals
      </h1>
      <p >
        At Geo Climate Risk Solutions (GCRS), we believe in creating a
        sustainable and resilient world for future generations.
        {!isMobile && <br />}
        Our commitment to the United Nations Sustainable Development Goals
        (SDGs) is at the core of our mission. We strive
        {!isMobile && <br />}
        to integrate these global goals into our business strategies,
        projects, and everyday practices. Focusing on SDGs 6, 9, 11,
        {!isMobile && <br />}
        13, 14, and 15, we are dedicated to making a meaningful impact in
        areas ranging from clean water and sanitation to
        {!isMobile && <br />}
        climate action and life on land.
      </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              
              alignItems: "center",
            }}
          >
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/sdg6.png"
              className="img-fluid"
              style={{ marginRight: "20px" ,marginTop:'20px'}}
              alt="sdg6"
            />
            <p style={{marginTop:'30px'}}>
              Ensuring availability and sustainable management of water and
              sanitation for all. GCRS focuses on 
              {!isMobile && <br />}
              projects that enhance water quality and promote efficient water
              usage.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              
              alignItems: "center",
              
            }}
          >
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/sdg9.png"
              className="img-fluid"
              style={{ marginRight: "20px" ,marginTop:'20px'}}
              alt="sdg9"
            />
            <p style={{marginTop:'30px'}}>
              Building resilient infrastructure, promoting inclusive and
              sustainable industrialization, and fostering  {!isMobile && <br />}
              innovation. GCRS integrates advanced technologies for sustainable
              industrial growth.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              
              alignItems: "center",
              
            }}
          >
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/sdg11.png"
              className="img-fluid"
              style={{ marginRight: "20px",marginTop:'20px' }}
              alt="sdg11"
            />
            <p style={{marginTop:'30px'}}>
              Making cities inclusive, safe, resilient, and sustainable. GCRS
              contributes to urban planning and  {!isMobile && <br />}
              management for environmental resilience.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
             
              alignItems: "center",
              
            }}
          >
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/sdg13.png"
              className="img-fluid"
              style={{ marginRight: "20px",marginTop:'20px' }}
              alt="sdg13"
            />
            <p style={{marginTop:'30px'}}>
              Taking urgent action to combat climate change and its impacts.
              GCRS is at the forefront of climate risk
              {!isMobile && <br />}
              assessment and mitigation strategies.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
             
              alignItems: "center",
              
            }}
          >
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/sdg14.png"
              className="img-fluid"
              style={{ marginRight: "20px" ,marginTop:'20px'}}
              alt="sdg14"
            />
            <p style={{marginTop:'30px'}}>
              Conserving and sustainably using the oceans, seas, and marine
              resources. GCRS's initiatives include
              {!isMobile && <br />}protecting marine ecosystems and addressing ocean
              pollution.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
             
              alignItems: "center",
              
            }}
          >
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Home_icons/sdg15.png"
              className="img-fluid"
              style={{ marginRight: "20px" ,marginTop:'20px'}}
              alt="sdg15"
            />
            <p style={{marginTop:'30px'}}>
              Protecting, restoring, and promoting sustainable use of
              terrestrial ecosystems. GCRS is committed to
              {!isMobile && <br />}
              land conservation and biodiversity.
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Comma;
