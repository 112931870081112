import React, { useState, useEffect, useRef } from "react";
import "./RandDdes.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const RandDdes = ({ setActiveTab }) => {
  const [activeTab, setActiveTabState] = useState("Knowledge-support");
  const [isSmallOrMediumScreen, setIsSmallOrMediumScreen] = useState(
    window.innerWidth < 768
  );
  const carouselRef = useRef(null);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setActiveTabState(tabId);
  };

  const tabData = [
    {
      id: "Knowledge-support",
      title: "Knowledge Support",
      iconSrc:
        require("../../src/assets/gcrs_images/RandD_icons/knowledge_support.svg")
          .default,
    },
    {
      id: "Research-partnerships",
      title: "Research Partnerships",
      iconSrc:
        require("../../src/assets/gcrs_images/RandD_icons/research_partnerships.svg")
          .default,
    },
    {
      id: "Research-projects",
      title: "Research projects",
      iconSrc:
        require("../../src/assets/gcrs_images/RandD_icons/research_projects.svg")
          .default,
    },
    {
      id: "Research-publications",
      title: "Research publications",
      iconSrc:
        require("../../src/assets/gcrs_images/RandD_icons/research_publications.svg")
          .default,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsSmallOrMediumScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCarouselChange = (index) => {
    const selectedTab = tabData[index].id;
    setActiveTab(selectedTab);
    setActiveTabState(selectedTab);
  };

  const handleLeftArrowClick = () => {
    const currentIndex = tabData.findIndex((tab) => tab.id === activeTab);
    const newIndex = currentIndex === 0 ? tabData.length - 1 : currentIndex - 1;
    const selectedTab = tabData[newIndex].id;
    setActiveTab(selectedTab);
    setActiveTabState(selectedTab);
    carouselRef.current.selectItem(newIndex);
  };

  const handleRightArrowClick = () => {
    const currentIndex = tabData.findIndex((tab) => tab.id === activeTab);
    const newIndex = currentIndex === tabData.length - 1 ? 0 : currentIndex + 1;
    const selectedTab = tabData[newIndex].id;
    setActiveTab(selectedTab);
    setActiveTabState(selectedTab);
    carouselRef.current.selectItem(newIndex);
  };

  return (
    <div className="row">
      {isSmallOrMediumScreen ? (
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={tabData.findIndex((tab) => tab.id === activeTab)}
          className="custom-carousel1"
          onChange={handleCarouselChange}
          ref={carouselRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {tabData.map((tab) => (
            <div
              key={tab.id}
              className={` ${activeTab === tab.id ? "active" : ""}`}
            >
              <a
                href={`#${tab.id}`}
                data-id={tab.id}
                className={`tab-link ${activeTab === tab.id ? "active" : ""}`}
                onClick={() => handleTabClick(tab.id)}
                onTouchStart={() => handleTabClick(tab.id)}
              >
                <div
                  className={`tab-content-container ${
                    activeTab === tab.id ? "active" : ""
                  }`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    marginLeft: "70px",
                    marginRight: "70px",
                  }}
                >
                  <span
                    className={`tab-link-icon ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                  >
                    <img
                      src={tab.iconSrc}
                      alt={tab.title}
                      style={{ height: "25px" }}
                    />
                  </span>
                  <span
                    className={`tab-link-title ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    style={{ color: "#000", fontSize: "13px" }}
                  >
                    {tab.title}
                  </span>
                </div>
              </a>
            </div>
          ))}
        </Carousel>
      ) : (
        <div>
          <section
            id="custom-tab-content"
            className="full-width"
            style={{ marginBottom: "20px", width: "70%" }}
          >
            <div className="custom-tab-content-wrapper">
              <div className="custom-tabs-link carousel">
                {tabData.map((tab) => (
                  <div
                    key={tab.id}
                    className={`${activeTab === tab.id ? "active" : ""}`}
                  >
                    <a
                      href={`#${tab.id}`}
                      data-id={tab.id}
                      className={`tab-link ${
                        activeTab === tab.id ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(tab.id)}
                      onTouchStart={() => handleTabClick(tab.id)}
                    >
                      <div
                        className={`tab-content-container ${
                          activeTab === tab.id ? "active" : ""
                        }`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <span
                          className={`tab-link-icon ${
                            activeTab === tab.id ? "active" : ""
                          }`}
                        >
                          <img
                            src={tab.iconSrc}
                            alt={tab.title}
                            style={{ height: "25px" }}
                          />
                        </span>
                        <span
                          className={`tab-link-title ${
                            activeTab === tab.id ? "active" : ""
                          }`}
                          style={{ color: "#000", fontSize: "13px" }}
                        >
                          {tab.title}
                        </span>
                      </div>
                    </a>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default RandDdes;
