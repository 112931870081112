import React from "react";
import "./Clients.css";

const Clients = () => {
  const imagePaths = [
    "Rectangle 47.png",
    "Rectangle 48.png",
    "Rectangle 49.png",
    "Rectangle 50.png",
    "Rectangle 51.png",
    "Rectangle 52.png",
    "Rectangle 54.png",
    "Rectangle 55.png",
    "Rectangle 56.png",
    "Rectangle 57.png",
    "Rectangle 58.png",
    "Rectangle 59.png",
    "Rectangle 60.png",
    "Rectangle 61.png",
    "Rectangle 62.png",
    "Rectangle 63.png",
    "Rectangle 64.png",
    "Rectangle 71.png",
    "Rectangle 72.png",
    "Rectangle 73.png",
    "Rectangle 74.png",
    "Rectangle 75.png",
    "Rectangle 76.png",
    "Rectangle 77.png",
    "Rectangle 78.png",
    "Rectangle 79.png",
    "Rectangle 80.png",
    "Rectangle 81.png",
    "Rectangle 82.png",
    "Rectangle 83.png",
    "Rectangle 84.png",
    "Rectangle 85.png",
    "Rectangle 86.png",
    "Rectangle 87.png",
    "Rectangle 88.png",
    "Rectangle 95.png",
    "Rectangle 96.png",
    "Rectangle 97.png",
    "Rectangle 98.png",
    "Rectangle 99.png",
    "Rectangle 100.png",
    "Rectangle 101.png",
    "Rectangle 102.png",
    "Rectangle 103.png",
    "Rectangle 104.png",
    "Rectangle 105.png",
    "Rectangle 106.png",
    "Rectangle 89.png",
    "Rectangle 90.png",
    "Rectangle 91.png",
    "Rectangle 92.png",
    "Rectangle 93.png",
    "Rectangle 94.png",
    "Rectangle 107.png",
    "Rectangle 108.png",
    "Rectangle 109.png",
    "Rectangle 110.png",
    "Dept of Environment & Remote Sensing_j&k.png",
  ];

  return (
    <div className="client-content">
      <h1 className="heading-text">Our Clients</h1>
      <p className="description-text">
        "Our collaborations encompass corporations, private equity firms,
        governmental bodies, non-profit organizations, multilateral and
        bilateral financiers, as well as donor agencies, as we offer
        comprehensive solutions."
      </p>

      {imagePaths
        .reduce((rows, path, index) => {
          const rowIndex = Math.floor(index / 6);
          if (!rows[rowIndex]) {
            rows[rowIndex] = [];
          }
          rows[rowIndex].push(
            <img
              src={require(`../../src/assets/Clients/${path}`)}
              style={{ margin: "1.5rem" }}
              key={index}
              alt={`Client ${index + 1}`}
            />
          );
          return rows;
        }, [])
        .map((row, index) => (
          <div className="image-row" key={index}>
            {row}
          </div>
        ))}
    </div>
  );
};

export default Clients;
