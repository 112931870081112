// NotFound.js

import React from "react";
import "./NotFoundPage.css";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div>
        <img
          src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/notfound.png"
          alt="notfound"
          className=""
        />
      </div>
      <div className="not-found-text">
        <h1 className="not-found-heading1">404</h1>
        <h1 className="not-found-heading2">Page Not Found!</h1>
        <p className="not-found-para">
          We’re sorry, the page you requested could not be found. <br />
          Please go back to the homepage!
        </p>
        <Link to="/">
          <button className="not-found-btn">Go Home</button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
